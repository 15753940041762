.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  .nav-mask {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
  }

  @media (orientation: landscape) {
    .api-doc{ 
      margin-left: 10px;
    }
    // 横屏
    div {
      height: 68px;
    }

    > button {
      width: 109px;
      height: 33px;
    }
  }

  @media (orientation: portrait) {
    .api-doc{
      display: none;
    
    }
    //竖屏
    div {
      height: 52px;
    }

    > button {
      width: 86px;
      height: 27px;
    }
  }


  .boxs {
    position: fixed;
    left: 3.89%;
    display: flex;
    // gap: 20px;
    align-items: center;

    .logo {
      height: 24px;
    }
    .apikeyleft {
      @media (orientation: landscape) {
        margin-left: 30px;
      }
      @media (orientation: portrait) {
        // margin-left: 35%;
      }
    }
    .apikey {
      width: 86px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: transparent;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      color: #F4F4F5;
      cursor: pointer;
      border-radius: 32px;
      border: 1px solid transparent;
      @media (orientation: landscape) {
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
      @media (orientation: portrait) {
      }
    }
  }

  > button {
    position: absolute;
    right: 3.89%;
    top: 50%;
    transform: translateY(50%);
    border-radius: 17px;
    border: 0.5px solid #FFFFFF;
    backdrop-filter: blur(6.728395061728395px);
    font-weight: 500;
    font-size: 14px;
    color: #fff;
  }
  .move-ani2{
    background: linear-gradient(  rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) );
  }
}