.homewraps {
  position: relative;
  overflow: hidden;

  .home {
    background: rgba(0, 0, 0, 0.3);

    position: relative;
    z-index: 2;
    color: #fff;

    @media (orientation: landscape) {
      @media (min-width: 1300px) {
        padding: 0 4vw 80px;
      }

      // height: 110vh;
      padding-bottom: 80px;

      .p-t {
        font-weight: 400;
        font-size: 14px;
      }

      .h-c {
        gap: 20px;
      }

      .h-t {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 28px;
        margin-left: 20px;
      }

      .t-t {
        flex: 0 0 23%;
        max-width: 287px; // 确保最大宽度
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), inset 2px 9px 24px 0px rgba(255, 255, 255, 0.17);
        border: 1px solid rgba(255, 255, 255, 0.17);
        overflow: hidden;
        .t-t-th{
          &:hover {
            scale: 1.05;
          }
        }
       

        .p-logo {
          width: 50%;
          .p-logo-bg {
            background-image: url('https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/texta.png');
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            object-fit: contain;
          }
        }

        .p-l {
          margin-top: 18px;
          margin-bottom: 20px;
        }

        .p-l-t {
          div {
            padding: 4px 12px;
            background: rgba(255, 255, 255, 0.09);
            border-radius: 14px;
            font-weight: 400;
            font-size: 10px;
            color: #D4D4D4;
          }
        }
      }
    }

    @media (orientation: portrait) {
      padding: 0 20px;
      padding-bottom: 55px;
      margin-top: 40px;
      .h-c {
        gap: 12px;
      }
      .h-t {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 28px;
      }
      .p-t {
        font-weight: 600;
        font-size: 11px;
        color: #F3F3F3;
      }

      .t-t {
        flex: 0 0 48%;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.50), inset 1px 5px 14px 0 rgba(255, 255, 255, 0.17);
        border: 0.5px solid rgba(255, 255, 255, 0.17);

        .p-logo {
          width: 10px;

          .p-logo-bg {
            display: none;

          }
        }

        .p-l {
          margin-top: 8px;
        }

        .p-l-t {
          div {
            padding: 2px 6px;
            background: rgba(255, 255, 255, 0.09);
            border-radius: 14px;
            font-weight: 400;
            font-size: 8px;
            color: #D4D4D4;
          }
        }
      }
    }

    .h-t {
      color: #FFFFFF;
    }

    .h-c {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .t-t {
      box-sizing: border-box;
      border-radius: 15px;
      max-width: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }

      .t-t-th {
        img {
          min-height: 123px;
          border-radius: 15px 15px 0 0;
        }
      }

      .t-t-td {
        box-sizing: border-box;
        padding: 8px;

        .p-t {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          color: #F3F3F3;
        }

        .p-l {
          display: flex;
          justify-content: space-between;
          align-items: center;


          .p-l-t {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .p-logo {
            cursor: pointer;
            position: relative;

            .tooltip {
              // display: none;
            }

            &:hover .tooltip {
              display: block;
            }

            img {
              height: 100%;
            }
          }
        }
      }
    }
  }
}