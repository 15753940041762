
.dowhat {

  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0 32px;
      height: 40px;
      font-weight: 500;
      font-size: 40px;
      color: #FFFFFF;
      line-height: 40px;
    }

    .left {
      width: 210px;
    }

    .right {
      width: 210px;
    }
  }

  @keyframes backgroundSize {
    from {
      background-size: 100%;
    }
    to {
      background-size: 120%;
    }
  }
  @keyframes backgroundSize_b {
    from {
      background-size: 120%;
    }
    to {
      background-size: 100%;
    }
  }
  @media (orientation: landscape) {
    padding: 145px 0;
    .m-title {
      display: none;
    }
  }
  @media (orientation: portrait) {
    .title {
      display: none;
    }
    .m-title {
      margin-left: 20px;
      color: white;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;

      div {
        width: 119px;
        height: 2px;
        background: white;
        margin-top: 3px;
      }
    }
  }

  .able {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (orientation: landscape) {

      margin-top: 120px;
      gap: 20px;
      > div {
        height: 325px;
      }
      .left {
        width: 273px;
      }

      .center {
        width: 464px;

        p {
          margin-bottom: 22px;
        }
      }

      .right {
        width: 369px;
      }
    }
    @media (orientation: portrait) {
      flex-direction: column;
      gap: 16px;
      margin: 36px 20px 0 20px;
      > div {
        width: 100%;
        aspect-ratio: 2 / 1.2;
      }
    }

    > div {
      background-size: 100%;
      background-position: top;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 32px 24px;
      box-sizing: border-box;
      border-radius: 20px;
      border: 1px solid #FFFFFF;
      box-shadow: inset 0 5px 10px 0 rgba(255, 255, 255, 0.5);
      overflow: hidden;

      > div {
        pointer-events: none;
        transform: translateY(80px);
        transition: transform 0.5s ease;

        h1 {
          pointer-events: none;
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;
          line-height: 33px;
        }

        p {
          pointer-events: none;
          @media (max-width: 700px) {
            margin-top: 20px;
          }
          @media (min-width: 700px) {
            margin-top: 35px;
          }
          font-weight: 400;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.55);
          line-height: 22px;
        }
      }
    }
  }
}