.homewrapt {
  position: relative;
  overflow: hidden;

  .bottom-mask {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    bottom: 0;
    height: 68px;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
  }

  .home {
    background: rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
    color: #fff;

    .h-t2 {
      color: #D4D4D4;
      margin-top: 16px;
    }

    @media (orientation: landscape) {
      min-height: 100vh;

      .h-t {
        font-weight: 500;
        font-size: 36px;
        letter-spacing: 1px;
      }

      .h-t,
      .h-t2 {
        text-align: center;
      }

      .h-t2 {
        margin-bottom: 84px;
        font-weight: 400;
        font-size: 16px;
        
      }

      // 横屏
    }

    @media (orientation: portrait) {
      padding: 0 20px;

      .h-t {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.5px;
      }

      .h-t2 {
        margin-bottom: 32px;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.5px;

      }
    }

    .h-c {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

    }

    .t-t {
      position: relative;
      flex: 0 0 100%;
      box-sizing: border-box;
      padding-left: 32px;
      padding-right: 44px;
      margin-bottom: 20px;
      box-sizing: border-box;
      border: 1px solid rgba(255, 255, 255, 0.17);

      .bg-u {
        background-image: url("https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/guang.png");
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 182px;
        height: 182px;
        z-index: -2;
      }

    }

    /* 媒体查询：横屏调整 */
    @media (min-width: 768px) and (orientation: landscape) {
      .t-t {
        flex: 0 0 48%;
        min-height: 185px;
        padding-top: 65px;
        padding-bottom: 25px;
        border-radius: 29px;
        box-shadow: inset 2px 9px 24px 0px rgba(255, 255, 255, 0.17);

        .bg-u { 
          background-size: 100% 100%;
        }
        .bg-t {
          width: 124px;
          height: 124px;
          position: absolute;
          top: -50px;
          left: -15px;
          background-image: url('https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/texta.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .t-t-th {
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;
          z-index: 33;
          letter-spacing: 1px;
        }

        .t-t-td {
          font-weight: 400;
          font-size: 15px;
          color: #D4D4D4;
          margin-top: 10px;
          letter-spacing: 1px;
        }
      }

      .h-c {
        gap: 20px;
      }

      @media (min-width: 1300px) {
        .h-c {
          padding: 0 185px;

        }
      }

    }

    /* 媒体查询：竖屏调整 */
    @media (orientation: portrait) {
      .t-t {
        flex: 0 0 100%;
        // height: 126px;
        padding-top: 29px;
        padding-bottom: 15px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 16px;
      box-shadow: inset 1px 4px 13px 0 rgba(255,255,255,0.17);

        .bg-u { 
          background-size: 142px 98px;

        }
        .bg-t {
          width: 65px;
          height: 65px;
          position: absolute;
          top: -26px;
          left: -0px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .t-t-th {
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
          letter-spacing: 0.5px;
        }

        .t-t-td {
          font-weight: 400;
          font-size: 12px;
          color: #D4D4D4;
          margin-top: 5px;
          letter-spacing: 0.5px;
          line-height: 17px;
        }
      }

      .h-c {
        padding: 0;
        margin-top: 45px;
      }
    }
  }

}