.mask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .2);
  z-index: 2;
}

.lyrics {
  position: absolute;
  @media (orientation: landscape) {

    bottom: 28%;
  }
  @media (orientation: portrait) {

    bottom: 25%;
  }
  left: 50%;
  transform: translateX(-50%);
  height: 81px;
  z-index: 3;
  overflow: hidden;

  ul {
    transition: all .5s;

    li {
      text-align: center;
      list-style: none;
      transition: all .5s;
      font-weight: 500;

      p {
        line-height: 27px;
        margin: 0;
        white-space: nowrap;
        color: #8A818B;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &:first-child {
          font-size: 14px;
        }

        &:last-child {
          font-size: 12px;
        }
      }
    }
  }
}

.active {
  p {
    color: #FFFFFF !important;
    background: inherit !important;
    -webkit-background-clip: inherit !important;
    -webkit-text-fill-color: inherit !important;

    &:first-child {
      font-size: 18px !important;
    }

    &:last-child {
      font-size: 13px !important;
    }
  }
}

.aimusic {
  background: black;
  overflow: hidden;

  @media (orientation: landscape) {
    margin: 0 9.72%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .text {
      max-width: 507px;
      flex: 1 1 0;

      h1 {
        margin: 46px 0 58px 0;
        height: 50px;
        font-size: 50px;
        line-height: 50px;
      }

      p:last-child {
        font-size: 18px;
        line-height: 27px;
      }
    }
    .video {
      position: relative;
      width: 653px;
      height: 653px;


      img {
        cursor: pointer;
        width: 65px;
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform-origin: left top;
        transform: translate(-50%, -50%);
        animation: playScale 1s infinite alternate;
      }

      @keyframes playScale {
        from {
          scale: 1;
        }
        to {
          scale: 1.1;
        }
      }

      .shadow {
        z-index: 5;
        width: 116px;
        height: 319px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);


        &:nth-of-type(1) {
          left: 0;
          background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.36) 100%);
        }

        &:nth-of-type(2) {
          right: 0;
          background: linear-gradient(to left, #000000 0%, rgba(0, 0, 0, 0.36) 100%);
        }
      }

      .music {
        top: 0;
        left: 0;
        position: absolute;
        width: 653px;
        height: 653px;

        &:nth-of-type(1) {
          z-index: 3;
        }

        &:nth-of-type(2) {
          z-index: 2;
        }

        &:nth-of-type(3) {
          z-index: 1;
        }
      }
    }
  }
  @media (orientation: portrait) {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      p:first-child {
        display: none;
      }

      p:last-child {
        font-size: 16px;
        line-height: 22px;
        padding: 20px 20px 0 20px;
      }

      h1 {
        text-align: center;
        font-size: 30px;
      }
    }
    .video {
      border-radius: 50%;
      overflow: hidden;
      position: relative;

      width: 100%;
      aspect-ratio: 1/1;


      img {
        width: 8%;
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform-origin: left top;
        transform: translate(-50%, -50%);
        animation: playScale 1s infinite alternate;
      }

      @keyframes playScale {
        from {
          scale: 1;
        }
        to {
          scale: 1.1;
        }
      }

      .shadow {
        display: none;
      }

      .music {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        aspect-ratio: 1/1;

        &:nth-of-type(1) {
          z-index: 3;
        }

        &:nth-of-type(2) {
          z-index: 2;
        }

        &:nth-of-type(3) {
          z-index: 1;
        }
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  .text {
    p:first-child {
      font-weight: 500;
      height: 12px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.55);
      line-height: 12px;
    }

    h1 {
      font-weight: 500;
      color: #FFFFFF;
      background: linear-gradient(180deg, #FFFFFF 0%, #BECAFF 100%);
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }

    p:last-child {
      color: rgba(255, 255, 255, 0.85);
      letter-spacing: 0.7px;
    }
  }


}