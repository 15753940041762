.img-diffuse {
  height: 100vh;
  position: relative;

  h1 {
    font-size: 80px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    white-space: nowrap;
    text-align: center;
    transform: translateY(-50%) translateX(-50%);
  }

  img {
    position: absolute;
    width: 100px;
    border-radius: 8px;
    object-fit: cover;
    transform-origin: center;
    &:nth-of-type(1) {
      width: 12%;
      left: 10%;
      top: 5%;
      z-index: 2;
    }

    &:nth-of-type(2) {
      width: 9%;
      filter: blur(3px);
      left: 7%;
      top: 20%;
      z-index: 1;
      opacity: 0.5;
    }

    &:nth-of-type(3) {
      width: 10%;
      left: 30%;
      top: 15%;
    }

    &:nth-of-type(4) {
      width:7%;
      left: 50%;
      top: 30%;
      filter: blur(2px);
      opacity: 0.8;
    }

    &:nth-of-type(5) {
      width: 10%;
      right: 25%;
      top: 5%;
    }

    &:nth-of-type(6) {
      width: 13%;
      right: 3%;
      top: 10%;
    }

    &:nth-of-type(7) {
      width: 10%;
      left: 10%;
      bottom: 5%;
    }

    &:nth-of-type(8) {
      width: 5%;
      left: 21%;
      bottom: 32%;
      filter: blur(5px);
      opacity: 0.8;
    }
    &:nth-of-type(9) {
      width: 8%;
      left: 24%;
      bottom: 16%;
    }
    &:nth-of-type(10) {
      width: 5%;
      left: 40%;
      bottom: 25%;
      filter: blur(5px);
      opacity: 0.8;
    }
    &:nth-of-type(11) {
      width: 9%;
      left: 42%;
      bottom: 2%;
    }
    &:nth-of-type(12) {
      width: 12%;
      right: 20%;
      bottom: 5%;
      z-index: 2;
    }
    &:nth-of-type(13) {
      width: 7%;
      right: 15%;
      bottom: 25%;
      z-index: 1;
      filter: blur(5px);
      opacity: 0.8;
    }
  }
}