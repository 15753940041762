@keyframes top {
  to {
    bottom: 0;
  }
}

.aiphoto {
  transform: translateY(-10%);
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (orientation: landscape) {
    // 横屏
    margin-top: 290px;
    padding-bottom: 145px;
    .m-imgs {
      display: none;
    }
  }

  @media (orientation: portrait) {
    //竖屏
    //padding-bottom: 105px;
    #certify {
      display: none;
    }
  }

  .m-imgs {
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;


    img {
      border-radius: 10px;
      width: 100%;
    }
  }


  #certify {

    position: relative;
    margin: 107px auto 0 auto;
    width: 1231px;
    min-height: 405px;

    .swiper-wrapper-s {
      display: flex;
      position: absolute;
      right: -205px;
      top: 0;

      .swiper-slide {
        z-index: 1;

        &:nth-of-type(1) {
          transform: translateX(150px) scale(0.8);
        }

        &:nth-of-type(2) {
          z-index: 2;
        }

        &:nth-of-type(3) {
          transform: translateX(-150px) scale(0.8);
        }
      }
    }

    .swiper-slide {
      width: 547px;
      height: 405px;
      border-radius: 18px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }

    }
  }

  .text {
    @media (orientation: landscape) {
      // 横屏
      h1 {
        text-align: center;
        font-size: 50px;
      }
      p:last-child {
        margin-top: 56px;
        line-height: 27px;
        font-size: 18px;
        width: 577px;
      }
    }

    @media (orientation: portrait) {
      //竖屏
      h1 {
        font-size: 30px;
        margin: 0 20px;
      }
      p:first-child {
        display: none;
      }
      p:last-child {
        text-align: left;
        line-height: 24px;
        font-size: 16px;
        margin: 16px 20px 0 20px;
      }
    }

    h1 {
      font-weight: 500;
      color: #FFFFFF;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
      background: linear-gradient(180deg, #FFFFFF 0%, #BECAFF 100%);
    }

    p {
      text-align: center;

      &:first-child {
        margin-bottom: 46px;
        font-weight: 500;
        height: 12px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.55);
        line-height: 12px;
      }

      &:last-child {
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        letter-spacing: 0.7px;
      }
    }
  }
}