@font-face {
  font-family: "iconfont"; /* Project id 4606712 */
  src: url('iconfont.woff2?t=1724304530553') format('woff2'),
       url('iconfont.woff?t=1724304530553') format('woff'),
       url('iconfont.ttf?t=1724304530553') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bm-choose_12:before {
  content: "\e640";
}

.bm-delete_12:before {
  content: "\e641";
}

.bm-history:before {
  content: "\e63e";
}

.bm-fold:before {
  content: "\e63f";
}

.bm-Message_information:before {
  content: "\e63a";
}

.bm-Message_success:before {
  content: "\e63b";
}

.bm-Message_warn:before {
  content: "\e63c";
}

.bm-Message_error:before {
  content: "\e63d";
}

.bm-add_40:before {
  content: "\e639";
}

.bm-show:before {
  content: "\e634";
}

.bm-edit:before {
  content: "\e635";
}

.bm-hide:before {
  content: "\e636";
}

.bm-check_line:before {
  content: "\e637";
}

.bm-arrow_down:before {
  content: "\e638";
}

.bm-detailedRedraw_select:before {
  content: "\e628";
}

.bm-brushSize_thick:before {
  content: "\e629";
}

.bm-i2i_normal:before {
  content: "\e62a";
}

.bm-delete_20:before {
  content: "\e62b";
}

.bm-addt2i:before {
  content: "\e62c";
}

.bm-t2i_select:before {
  content: "\e62d";
}

.bm-more1:before {
  content: "\e62e";
}

.bm-delete2:before {
  content: "\e62f";
}

.bm-i2i_secelt:before {
  content: "\e630";
}

.bm-arrow_right:before {
  content: "\e631";
}

.bm-eraserSize_slim:before {
  content: "\e632";
}

.bm-download2:before {
  content: "\e633";
}

.bm-maskingTools:before {
  content: "\e616";
}

.bm-undo:before {
  content: "\e617";
}

.bm-i2i_disable:before {
  content: "\e618";
}

.bm-eraserSize_thick:before {
  content: "\e619";
}

.bm-redo:before {
  content: "\e61a";
}

.bm-addimg2:before {
  content: "\e61b";
}

.bm-brushTool:before {
  content: "\e61c";
}

.bm-arrow_lift:before {
  content: "\e61d";
}

.bm-detailedRedraw_normal:before {
  content: "\e61e";
}

.bm-addimg_normal:before {
  content: "\e61f";
}

.bm-upsacle2:before {
  content: "\e620";
}

.bm-brushSize_slim:before {
  content: "\e621";
}

.bm-t2i_normal:before {
  content: "\e622";
}

.bm-selectTools:before {
  content: "\e623";
}

.bm-check_flat:before {
  content: "\e624";
}

.bm-close:before {
  content: "\e625";
}

.bm-eraser:before {
  content: "\e626";
}

.bm-suiji_normal:before {
  content: "\e627";
}

.bm-straw:before {
  content: "\e611";
}

.bm-compare:before {
  content: "\e612";
}

.bm-wechat:before {
  content: "\e613";
}

.bm-website:before {
  content: "\e614";
}

.bm-refresh:before {
  content: "\e615";
}

.bm-sendi2i:before {
  content: "\e609";
}

.bm-sendcanvas:before {
  content: "\e60a";
}

.bm-report:before {
  content: "\e60b";
}

.bm-redraw:before {
  content: "\e60c";
}

.bm-more:before {
  content: "\e60d";
}

.bm-upsacle:before {
  content: "\e60e";
}

.bm-download:before {
  content: "\e60f";
}

.bm-delete:before {
  content: "\e610";
}

.bm-arrow_bend_20:before {
  content: "\e608";
}

