.aivideo {
  overflow: hidden;
  .video-list {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;

    video {
      width: 100%;
      border-radius: 10px;
      //height: 189px;
      object-fit: cover;
    }
  }
  @media (orientation: landscape) {
    // 横屏
    margin: 145px 9.72%;
    .video-list{
      display: none;
    }
    display: flex;
    justify-content: space-around;
    align-items: center;
    .videos {
      width: 693px;
    }
    .text {
      height: 670.48px;
    }
    h1 {
      margin: 46px 0 58px 0;
      font-size: 50px;
    }
    p:last-child {
      line-height: 27px;
      font-size: 18px;
      max-width: 514px;
    }
  }

  @media (orientation: portrait) {
    //竖屏
    .videos {
      display: none;
    }
    .text {
      p:first-child {
        display: none;
      }

      h1 {
        font-size: 30px;
        margin: 0 20px;
      }

      p:last-child {
        margin: 16px 20px;
        line-height: 22px;
        font-size: 16px;
      }
    }
  }

  .text {
    p:first-child {
      font-weight: 500;
      height: 12px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.55);
      line-height: 12px;
    }

    h1 {
      font-weight: 500;
      color: #FFFFFF;
      background: linear-gradient(180deg, #FFFFFF 0%, #BECAFF 100%);
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }

    p:last-child {
      color: rgba(255, 255, 255, 0.85);
      letter-spacing: 0.7px;
    }
  }

  .videos {
    > div > div {
      overflow: hidden;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .top {
      display: flex;
      justify-content: end;

      .top-video {
        width: 487px;
        height: 237px;
        border-radius: 20px;
      }
    }

    .center {
      margin-top: 12px;
      display: flex;
      align-items: center;
      gap: 12px;

      .center-left-video {
        border-radius: 20px;
        height: 174px;
        width: 393px;
      }

      .center-right-video {
        border-radius: 20px;
        height: 174px;
        width: 288px;
      }

    }

    .bottom {
      display: flex;
      gap: 12px;
      margin-top: 12px;

      .bottom-left-video {
        border-radius: 20px;
        width: 212px;
        height: 131px;
      }

      .bottom-right-video {
        border-radius: 20px;
        width: 438px;
        height: 246px;
      }
    }
  }
}