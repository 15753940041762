.homewrap {
  position: relative;
  overflow: hidden;

  .bottom-mask {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    bottom: 0;
    height: 68px;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
  }

  .video {
    position: absolute;
    top: 0;
    z-index: 1;
    right: 0;
    left: 0;
    bottom: 0;

    video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .home {

    background: rgba(0, 0, 0, 0.3);
    height: 100vh;
    position: relative;
    z-index: 2;


    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;
      @media (orientation: landscape) {
        // 横屏
        > button {
          width: 174px;
          height: 49px;
          font-size: 18px;
          margin-top: 84px;

          span {
            font-size: 22px;
            margin-left: 6px;
          }
        }
        h1 {
          font-size: 54px;
        }
        p {
          margin-top: 20px;
          font-size: 25px;
        }
      }

      @media (orientation: portrait) {
        //竖屏
        > button {
          width: 139px;
          height: 39px;
          font-size: 17px;
          margin-top: 54px;

          span {
            font-size: 17.14px;
          }
        }
        h1 {
          font-size: 30px;
          line-height: 39px;
          margin: 0 25px;
        }
        p {
          margin-top: 36px;
          font-size: 16px;
        }
      }

      h1 {
        text-align: center;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 4px;
      }

      p {
        letter-spacing: 2px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.95);
      }


      > button {
        letter-spacing: 2px;
        border-radius: 25px;
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(10px);
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}