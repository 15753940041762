.ant-modal-content {
    background-color: #1c1c1c !important;
    color: #DADADA !important;
    padding: 0 !important;
    @media (orientation: landscape) { 
        border-radius: 30px !important;
    }
    @media (orientation: portrait) { 
        border-radius: 0px !important;
        width: 100vw !important;
        min-height: 100vh !important;
        margin-left: -10px !important;
        margin-top: -10px !important;
    }
}

.ant-modal-close-x {
    color: #EEEEEE !important;
}

.ant-form-item-required {
    color: #DADADA !important;

    &::after {
        color: #fff !important;
    }
}

.ant-input-outlined {
    background: #111111 !important;
    border-color: #111111 !important;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: #111111 !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
    background: #111111 !important;
    border-color: #9568fa !important;
}

.ant-input-outlined:hover {
    background: #111111 !important;
    border-color: #9568fa !important;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
    background: #111111 !important;
    border-color: #9568fa !important;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus,
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus-within {
    background: #111111 !important;
    border-color: #9568fa !important;
}

  textarea#validateOnly_Message::-webkit-scrollbar-track {
    background:validateOnly_Message ; /* 滚动条轨道的背景颜色 */
  }
  
  textarea#validateOnly_Message::-webkit-scrollbar-thumb {
    background: #4d4d4d; /* 滚动条滑块的颜色 */
    border-radius: 55px; /* 滚动条滑块的圆角 */
  }
  
  textarea#validateOnly_Message::-webkit-scrollbar-thumb:hover {
    background: #4d4d4d; /* 滚动条滑块悬停时的颜色 */
    border-radius: 15px !important; 
    height: 20px; 
  }
  
  textarea#validateOnly_Message {
    scrollbar-width: none; /* 滚动条的宽度，可选值：auto, thin, none */
    scrollbar-color: #fff #111111; /* 滚动条的颜色和轨道的颜色 */
  }
.ant-input {
    background: #111111;
    color: #DADADA !important;
    border-radius: 15px !important;
}

@media (orientation: portrait) {
    .ant-modal-content {
        min-height: 100vh !important;
    }
}

.my-model {

    @media (orientation: landscape) {
        display: flex;

        .my-model-l {
            width: 559px;
            height: 609px;
            background-image: url("https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/lianxiwomenzuotu.png");
            padding-top: 41px;
            box-sizing: border-box;

            p {
                margin-left: 45px;
            }
        }

        .my-model-r {
            box-sizing: border-box;
            padding: 50px;
            width: 559px;
        }
    }

    @media (orientation: portrait) {
        .my-model-l {
            width: 100%;
            height: 239px;
            background-image: url("https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/shoujitupianshang.png");
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                margin-left: 28px;
            }
        }

        .my-model-r {
            padding: 20px;
            min-height: calc(100vh - 239px);
            padding-bottom: 100px;
        }
    }

    .my-model-l {
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @media (orientation: landscape) { 
            .p1,.p2 { 
                font-size: 44px;
                line-height: 80px;
                letter-spacing: 1px;
            }
        }
        @media (orientation: portrait) { 
            .p1,.p2 { 
                    font-size: 32px;
                 line-height: 45px;
            }
        }

        .p1 {
            font-weight: 500;
            color: #FFFFFF;
            line-height: 45px;
        }

        .p2 {
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 11px;
        }

        .p3 {
            font-weight: 400;
            font-size: 17px;
            color: #dbdbdb;
            line-height: 17px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .my-model-r {
        box-sizing: border-box;
        // padding: 50px;
        // width: 559px;
    }
}

.Space-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #DADADA;
}

.Space-item-h5 {
    color: #DADADA;
}

.ant-space-item {
    width: 100% !important;
}

.btn-submit {
    background: linear-gradient(270deg, #EC73F9 0%, #6D62FA 100%);
    border-radius: 30px;
    border: 0;
    color: #FFFFFF;

    @media (orientation: landscape) {
        width: 120px;
        height: 48px;
    }

    @media (orientation: portrait) {
        width: 100%;
        height: 46px;
    }

    &:hover {
        background: linear-gradient(270deg, #EC73F9 0%, #8e6af2 100%) !important;
        color: #FFFFFF !important;
    }

    &::after {
        border: 0 !important;
    }

    &::before {
        border: 0 !important;
    }
}

.ant-btn-variant-solid:disabled,
.ant-btn-variant-solid.ant-btn-disabled {
    background: #4d4d4d !important;
    color: #B5B5B5 !important;
}