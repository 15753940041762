//动画区域显示
@keyframes AniShow {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//标题文本显示
@keyframes titleShow {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@keyframes titleHide {
  from {
    opacity: 1;

  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}


//文本隐藏
@keyframes textHide {
  to {
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes textHide_b {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

//平板电脑动画
@keyframes tabletUp {
  from {
    transform: rotateX(45deg);
  }
  to {
    transform: rotateX(0);
  }
}

@keyframes tabletDown {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(45deg);
  }
}

//第二帧开始
@keyframes tablet_two {
  from {
    transform: rotateX(0);
    scale: 1;
    opacity: 1;
  }
  to {
    transform: rotateX(0);
    scale: 0;
    opacity: 0;
  }
}

@keyframes tablet_two_b {
  from {
    transform: rotateX(0);
    scale: 0;
    opacity: 0;
  }
  to {
    transform: rotateX(0);
    scale: 1;
    opacity: 1;
  }
}

//打字机
@keyframes input {
  from {
    transform: translateY(-50%) translateX(-50%) scale(0.1);
    opacity: 0;
  }
  to {
    transform: translateY(-50%) translateX(-50%) scale(1);
    opacity: 1;
  }
}

@keyframes input_b {
  from {
    transform: translateY(-50%) translateX(-50%) scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(-50%) translateX(-50%) scale(0.1);
    opacity: 0;
  }
}

//左边花
@keyframes flower_left {
  from {
    transform: translate(-720px, 68%);
    opacity: 1;
  }
  to {
    transform: translate(-720px, 0);
    opacity: 0;
  }
}

@keyframes flower_left_b {
  from {
    transform: translate(-720px, 0);
    opacity: 0;
  }
  to {
    transform: translate(-720px, 68%);
    opacity: 1;
  }
}

//右边花
@keyframes flower_right {
  from {
    transform: translate(190px, 23%);
    opacity: 1;
  }
  to {
    transform: translate(190px, -45%);
    opacity: 0;
  }
}


@keyframes flower_right_b {
  from {
    transform: translate(190px, -45%);
    opacity: 0;
  }
  to {
    transform: translate(190px, 23%);
    opacity: 1;
  }
}

//墨水按钮
@keyframes ink_btn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

//打字机效果
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes typing_b {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}




