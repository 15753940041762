.homewrap2 {
  position: relative;
  overflow: hidden;

  background-repeat: no-repeat;
  background-position: top left;
  // background-size: contain;
  @media (orientation: landscape) { 
    background-image: url("https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/zuoguang.png");
    background-size: 214px 438px;

  }
  @media (orientation: portrait) { 
    background-image: url("https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/guang_phone.png");
    background-size: 111px 243px;
  }
  .h-bg {
    width: 214px;
  }

  @media (orientation: landscape) {
    .home-portrait {
      display: none;
    }

    .home {
      background: rgba(0, 0, 0, 0.3);
      min-height: 100vh;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (orientation: landscape) {

        // 横屏
        @media (min-width: 1300px) {
          padding-left: 110px;
        }

        @media (max-width: 1300px) {
          padding-left: 30px;
        }

        .home-l {
          width: 45vw;
        }

        .home-r {
          width: 45vw;
          background-image: url('https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/shouyezhutu.png');
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      // 竖屏
      @media (orientation: portrait) {
        .home-l {
          padding-left: 20px;
          padding-right: 20px;
        }

        .home-r {
          width: 90vw;
          height: 90vw;
          background-image: url('https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/shouyetupian.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .home-l,
    .home-r {
      box-sizing: border-box;
      flex: 1;
      box-sizing: border-box;
      padding: 10px;
      color: #fff;

      @media (orientation: landscape) {

        // 横屏
        >button {
          width: 278px;
          height: 60px;
          font-size: 18px;
          border-radius: 30px;
        }

        @media (max-width:960px) {
          margin-top: 50px;
        }

        .l-1 {
          width: 40vw;
          font-size: 2.8rem;
          line-height: 67px;
          letter-spacing: 1px;
        }

        .l-2 {
          width: 38vw;
          margin: 68px 0 60px;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }

      @media (orientation: portrait) {

        //竖屏
        >button {
          width: 139px;
          height: 39px;
          font-size: 17px;
        }

        .l-1 {
          width: 90vw;
          font-size: 38px;
        }

        .l-2 {
          width: 90vw;
          margin: 28px 0 40px;
        }
      }
    }

    .home-l {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .l-1 {
        font-weight: 500;
        color: #FFFFFF;
      }

      .l-2 {
        font-weight: 400;
        font-size: 16px;
        color: #D4D4D4;
        line-height: 22px;
      }

      >button {
        letter-spacing: 2px;
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(10px);
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (orientation: portrait) {
    .home {
      display: none;
    }

    .home-portrait {
      padding-top: 53px;
      box-sizing: border-box;

      >button {
        margin: 32px 0 20px 20px; 
        width: 146px;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        border-radius: 25px;
        border: 0.5px solid #FFFFFF;
        backdrop-filter: blur(10px);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .home-two {
        display: flex;
        justify-content: space-between;
        color: #fff;
        // padding-left: 34px;

        .home-l {
          .l-1 {
            box-sizing: border-box;
            padding: 0 20px;
            margin-top: 50px;
            font-weight: 500;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 33px;
            text-align: left;
            letter-spacing: 0.5px; 
            margin-bottom: 20px;
          }
        }


      }

      .l-2 {
        padding: 0 20px;
        font-weight: 400;
        font-size: 14px;
        color: #D4D4D4;
        line-height: 20px;
        text-align: left;
        letter-spacing: 0.5px; 

      }
    }

    .home-r {
      margin-top: -70px;
      margin-bottom: -50px;
      width: 100%;
      height: 456px;
      background-image: url('https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/toutu_phone.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}