
.team {
  overflow: hidden;
  padding: 145px 0;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  .content {

    h1 {
      font-weight: 500;
      font-size: 40px;
      text-align: center;
      color: #FFFFFF;
      line-height: 40px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      margin-top: 32px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.8);
    }

    .speed {
      position: relative;
      z-index: 2;
      width: 100%;
      display: flex;
      gap: 25px;
      justify-content: right;
      margin-top: 64px;

      > div {
        cursor: pointer;
        width: 63px;
        height: 63px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 19px;
          height: 17px;
        }
      }

      .left {
        background: #343434;
      }

      .right {
        margin-right: 138px;
        background: white;
      }
    }

    .photowrap {
      position: relative;

      .leftMask {
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 385px;
        height: 609px;
        background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      }

      .rightMask {
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 385px;
        height: 609px;
        background: linear-gradient(to left, #000000 0%, rgba(0, 0, 0, 0) 100%);
      }

      .photo {
        margin-top: 76px;
        display: flex;
        gap: 12px;

        .member {
          width: 222px;

          img {
            width: 222px;
            height: 349px;
          }

          p {
            line-height: 20px;
            margin-top: 28px;
            font-size: 20px;
            color: #FFFFFF;
          }
        }
      }
    }

  }
}

.m-team {
  margin-top: 145px;
  margin-bottom: 50px;
  overflow: hidden;

  .title {
    padding: 0 20px;

    h1 {
      font-weight: 500;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 33px;
    }

    p {
      margin-top: 6px;
      font-weight: 400;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 24px;
    }
  }

  .photowrap {
    position: relative;

    .leftMask {
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100px;
      height: 300px;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    .rightMask {
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 100px;
      height: 300px;
      background: linear-gradient(to left, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    .photo {
      width: 100%;
      display: flex;
      gap: 7px;

      margin-top: 26px;

      .member {
        width: 147px;

        img {
          width: 147px;
        }

        p {
          font-weight: 600;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 14px;
          margin: 15px 0;
          text-align: center;
        }
      }
    }
  }
}