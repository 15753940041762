.m-imgmode {
  margin-top: 145px;
  position: relative;
  overflow: hidden;

  .text {
    h1 {
      font-weight: 500;
      font-size: 30px;
      line-height: 34px;
      text-align: center;
      background: linear-gradient(180deg, #FFFFFF 0%, #BECAFF 100%);
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }

    .intro {
      text-align: center;
      letter-spacing: 0.7px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.85);
      margin: 16px 20px;
    }

  }

  .ani {
    position: relative;
    margin-top: 0;

    > div {
      &:first-child {
        &::after {
          content: "";
          display: table; /* 或者 block，table 对布局兼容性更好 */
          clear: both; /* 清除左右浮动 */
        }
      }
    }

    .tabletWrap {
      perspective: 1000px;
      z-index: 3;
      display: flex;
      transform: translateY(-60%);
      justify-content: center;

      .tablet {
        width: 90%;
        transform: rotateX(45deg);
        //transform: rotateX(0);
        transform-style: preserve-3d;
      }
    }


    .flower_left {
      float: left;
      width: 30%;
      transform: translateY(110%);
      margin-left: -10%;
    }

    .flower_right {
      float: right;
      width: 50%;
      transform: translateY(30%);
      margin-right: -12%;
    }
  }
}

.imgmode {
  height: 100vh;
  padding-top: 290px;
  background-color: #000000;
  position: relative;
  z-index: 9;
  overflow: hidden;

  .ani {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;


    .input {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0.1);
      opacity: 0;
      width: 1386px;
      height: 255px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 119px;
      box-sizing: border-box;

      .iconfont {
        font-size: 24px;
        color: #A0A0A0;
      }

      .input-center {
        p {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.75);

          white-space: nowrap;
          overflow: hidden;
          width: 0;
        }
      }

      .input-right {
        display: flex;
        align-items: center;
        gap: 10px;

        > div:first-child {
          width: 46px;
          height: 46px;
          background: #333333;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

        }

        > div:last-child {
          width: 99px;
          height: 48px;
          background: #FEF8E3;
          border-radius: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          animation: ink_btn 1s infinite alternate;

          img {
            width: 24px;
            height: 24px;
          }

          p {
            height: 30px;
            font-weight: 500;
            font-size: 22px;
            color: #000000;
            line-height: 30px;
          }
        }
      }

      .input-left {
        display: flex;
        align-items: center;
        gap: 10px;

        > div {
          width: 46px;
          height: 46px;
          background: #333333;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

        }
      }
    }

    .flower_left {
      left: 50%;
      width: 362px;
      position: absolute;
      transform: translate(-720px, 120%);
      z-index: 1;
      height: 371px;
    }

    .flower_right {
      left: 50%;
      width: 547px;
      position: absolute;
      z-index: 1;
      height: 673px;
      transform: translate(190px, 40%);
    }

    .tabletWrap {
      perspective: 2000px;
      position: absolute;
      z-index: 3;
      transform: translate(-50%);
      left: 50%;
      top: 40%;

      .tablet {
        width: 920px;
        height: 560px;
        transform: rotateX(45deg);
        transform-style: preserve-3d;
      }
    }

  }

  .text {
    position: absolute;
    right: 0;
    left: 0;
    top: 10%;
    z-index: 4;

    h1 {
      font-weight: 500;
      font-size: 50px;
      text-align: center;
      background: linear-gradient(180deg, #FFFFFF 0%, #BECAFF 100%);
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }

    .smalltitle {
      text-align: center;
      margin-bottom: 46px;
      font-weight: 500;
      height: 12px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.55);
      line-height: 12px;
    }

    .intro {
      letter-spacing: 0.7px;
      text-align: center;
      font-size: 18px;
      line-height: 27px;
      color: rgba(255, 255, 255, 0.85);
      margin-top: 51px;
    }

  }

}