@media (orientation: landscape) {
  // 横屏
}

@media (orientation: portrait) {
  //竖屏
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: "PingFang SC", sans-serif;
}
button{
  cursor: pointer;
}

//body::-webkit-scrollbar {
//  display: none;
//}

.move-ani {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 100%);
  background-size: 200% 100%;
  background-position: 100% 50%; /* 初始位置设置为右边 */
  transition: background-position 0.3s linear, color 0.3s linear;

  &:hover {
    color: #000 !important;
    background-position: 0 50%; /* 鼠标悬停时，背景向左移动 */
  }

  &:active {
    color: #fff !important;
    background-position: 100% 50%; /* 移开鼠标时，背景回到右边 */
  }
}