.m-brand {
  padding: 55px 25px 50px 25px;
  background: white;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  @media (orientation: landscape) {
    display: none;
  }
  @media (orientation: portrait) {
    @media (max-width: 700px) {
      //手机

      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 700px) {
      grid-template-columns: repeat(5, 1fr);
      //平板
    }
  }
  img {
    border-radius: 8px;
    border: 1px solid #EDEDED;
    width: 100%;
  }
}
.footer-wrap {
  background-color: white;
  @media (orientation: landscape) {
    height: 360px;
    .m-brand {
      display: none;
    }
  }
  @media (orientation: portrait) {
    height: 190px;
    .brand {
      display: none;
    }
  }
  position: relative;
  overflow: hidden;

  .brand {
    overflow: hidden;

    div {
      display: flex;
      align-items: center;

      img {
        @media (orientation: landscape) {
          height: 86px;
          &:nth-of-type(2) {
            margin-left: 205px;
          }
        }
        @media (orientation: portrait) {
          height: 26px;
          &:nth-of-type(2) {
            margin-left: 65px;
          }
        }
      }
    }
  }

  .astronaut {
    position: absolute;
    @media (orientation: landscape) {
      height: 258px;
      bottom: 0;
      right: 0;
    }
    @media (orientation: portrait) {
      height: 121px;
      bottom: 0;
      right: -30px;
    }
  }

  .contact {
    .aniText{
      text-align: center;
    }
    .btnlx{
      // width: 278px;
      // height: 60px;
      background-color: #171717;
      border-radius: 100px;
      font-weight: 500;
color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
    }
    @media (orientation: landscape) {
      .btnlx{ 
      width: 278px;
      height: 60px;
font-size: 20px;

      }
      top: 137px;
      left: 147px;
      h1 {
        font-size: 40px;
      }
      p {
        user-select: text;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        font-size: 13px;
        margin-top: 17px;
      }
    }
    @media (orientation: portrait) {
      .aniText{
       margin-bottom: 20px;
      }
      .btnlx{
        margin: 10px auto 10px;
        width: 137px;
        height: 36px;
        font-size: 14px;
      }
      left: 18px;
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 13px;
        margin-top: 4px;
      }
    }
    position: absolute;
    color: #000000;
  }

  .info {
    position: absolute;
    display: flex;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);

    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.55);
    }

    .hr {
      position: relative;
      left: -19px;
      margin-bottom: 17px;
      width: 220px;
      height: 3px;
      background: #EFEFEF;
    }

    @media (orientation: landscape) {
      right: 0;
      left: 0;
      bottom: 0;
      height: 44px;
      align-items: center;
      margin-left: 143px;
      gap: 104px;
      .hr {
        display: none;
      }
    }
    @media (orientation: portrait) {
      flex-direction: column;
      gap: 2px;
      left: 19px;
      bottom: 9px;
      a {
        font-size: 10px;
        line-height: 12px;
      }
      p {
        margin-bottom: 16px;
        font-weight: 300;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 12px;
      }
    }


  }
}



